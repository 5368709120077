import {setCookie, deleteCookie} from '~hooks/useCookie'
import {fetchWrapper, responseWrapper} from '~helpers/functions'

export async function login(data, ctx = null, options = {}) {
	const response = await createToken(data, ctx, options)

	if (response['success']) {
		;['access_token', 'access_token_expire_at', 'refresh_token', 'user'].forEach((key) => {
			if (response['body']?.[key]) {
				const value = typeof response['body'][key] === 'object' ? JSON.stringify(response['body'][key]) : response['body'][key]

				setCookie(key, value, {
					...(ctx && {req: ctx['req'], res: ctx['res']}),
					...{path: '/'},
				})
			}
		})
	}

	return response
}

export async function register(data, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/registrations', ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})

	if (response['success']) {
		;['access_token', 'access_token_expire_at', 'refresh_token', 'user'].forEach((key) => {
			if (response['body']?.[key]) {
				const value = typeof response['body'][key] === 'object' ? JSON.stringify(response['body'][key]) : response['body'][key]

				setCookie(key, value, {
					...(ctx && {req: ctx['req'], res: ctx['res']}),
					...{path: '/'},
				})
			}
		})
	}

	return response
}

export async function logout(reload = true, ctx = null) {
	;['access_token', 'access_token_expire_at', 'refresh_token', 'user'].forEach((key) => {
		deleteCookie(key, {
			...(ctx && {req: ctx['req'], res: ctx['res']}),
			...{path: '/'},
		})
	})

	if (!ctx && reload && typeof window !== 'undefined') {
		window.location.reload()
	} else {
		return true
	}
}

export async function createToken(data, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/tokens/create', ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	}, true)
}

export async function refreshToken(data, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/tokens/refresh', ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	}, true)
}

//TODO: дополнить урл как бэкенд будет готов
export async function resetPassword(data, ctx, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/profile/forgot_password', ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})
}
